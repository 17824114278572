import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import CheckouPage from "./Checkout";
import CheckoutResultPage from "./CheckoutResult";

export default function CheckoutRouting() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CheckouPage />
      </Route>
      <Route path={`${path}/result`}>
        <CheckoutResultPage />
      </Route>
    </Switch>
  );
}
