/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

const AccordionItem = (props) => {
	return (
		<div className="single_faq_accordian wow fadeInLeft" data-wow-duration="2s" data-wow-delay={props.wowDelay}>
      <div className="faq_accordian_header">
        <a
          href="#"
          className="collapsed"
          id={props.headingId}
          data-toggle="collapse"
          data-target={"#" + props.collapseId}
          aria-expanded="false"
          aria-controls={props.collapseId}
        >
          {props.title}
        </a>
      </div>
      <div id={props.collapseId} className="collapse" aria-labelledby={props.headingId}
        data-parent="#accordionExample">
        <div className="faq_accordian_body">
          {props.children}
        </div>
      </div>
    </div>
	);
};

export default AccordionItem;
