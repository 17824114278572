import React from 'react';
import { useTranslation } from 'react-i18next';

import products from "../../../../data/products.json";

export default function Choose() {
  const { t } = useTranslation();

  return (
    <section className="pelum-choose-area colored-area" id="pourquoi">
      <div className="container">
        <div className="full-width">
          <div className="row">
            <div className="col-lg-6">
              <div className="choose-left">
                <div className="choose-heading wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                  <h4>{t("Pourquoi utiliser le EARS")}</h4>
                  <h2>{t("Le EARS est un produit Français 100% biocompatible et écologique")}</h2>
                  <span className="heading_overlay"></span>
                </div>
                <ul>
                  <li className="active choose-box wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                    <div className="choose-icon">
                      <i className="flaticon-quality"></i>
                    </div>
                    <div className="choose-text">
                      <h3>{t("Agréable au toucher")}</h3>
                      <p>{t("Les EARS ont été pensés pour être doux et souples entre les doigts lors de leur utilisation. Conseils d’utilisation pendant ou après sa toilette.")}</p>
                    </div>
                  </li>
                  <li className="choose-box wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.7s">
                    <div className="choose-icon">
                      <i className="flaticon-quality"></i>
                    </div>
                    <div className="choose-text">
                      <h3>{t("En SEBS médical")}</h3>
                      <p>{t("Le SEBS est une matière première issu du caoutchouc qui a une approbation médicale qui garantit la biocompatibilité des EARS.")}</p>
                    </div>
                  </li>
                  <li className="choose-box wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
                    <div className="choose-icon">
                      <i className="flaticon-quality"></i>
                    </div>
                    <div className="choose-text">
                      <h3>{t("Lavables & Réutilisables")}</h3>
                      <p>{t("Après chaque utilisation les EARS peuvent être lavés et rangés soigneusement et sont utilisable plusieurs années.")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="choose-right wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.5s">
                <div className="choose-slider owl-carousel">
                  {
                    products.map((item, idx) => (
                      <div className="single-choose-item" key={idx}>
                          <img src={item.photoUrl} alt={item.name} />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
