import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Features() {
  const { t } = useTranslation();

  return (
    <section className="pelum-features-area darked-area secondary" id="features">
      <div className="container">
        <div className="full-width">
          <div className="row">
              <div className="col-lg-12">
                <div className="site-heading">
                    <h4>{t("Pourquoi utiliser le EARS")}</h4>
                    <h2>{t("Le EARS est un produit Français 100% biocompatible")}</h2>
                    <span className="heading_overlay"></span>
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="feature-icon">
                      <i className="fa fa-diamond"></i>
                    </div>
                    <div className="feature-text">
                      <h3>{t("Agréable au toucher")}</h3>
                      <p>{t("Les EARS ont été pensés pour être doux et souples entre les doigts lors de leur utilisation. Conseils d’utilisation pendant ou après sa toilette.")}</p>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                    <div className="feature-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="feature-text">
                      <h3>{t("En SEBS médical")}</h3>
                      <p>{t("Le SEBS est une matière première issu du caoutchouc qui a une approbation médicale qui garantit la biocompatibilité des EARS.")}</p>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.8s">
                    <div className="feature-icon">
                      <i className="fa fa-smile-o"></i>
                    </div>
                    <div className="feature-text">
                      <h3>{t("Écologique et Économique")}</h3>
                      <p>{t("Les EARS ne dégradent pas l’environnement et sont recyclables.")}</p>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.3s">
                    <div className="feature-icon">
                      <i className="fa fa-thumbs-up"></i>
                    </div>
                    <div className="feature-text">
                      <h3>{t("Lavables & Réutilisables")}</h3>
                      <p>{t("Après chaque utilisation les EARS peuvent être lavés et rangés soigneusement et sont utilisable plusieurs années.")}</p>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};
