import React from 'react';
import { useTranslation } from 'react-i18next';

import TestimonialItem from "../components/TestimonialItem";
import testimonials from "../../../../data/testimonials.json";

export default function Testimonial() {
  const { t } = useTranslation();

  return (
    <section className="pelum-testimonial-area darked-area primary" id="temoignages">
      <div className="container">
        <div className="full-width">
          <div className="row">
            <div className="col-lg-12">
              <div className="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                <h4>{t("Témoignages")}</h4>
                <h2>{t("Nos clients en parlent")}</h2>
                <span className="heading_overlay"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="testimonial-slider owl-carousel wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
                {testimonials.map((item, idx) => <TestimonialItem key={idx} item={item} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
