import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";

import CONFIG from "./app/utils/config";
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import "firebase/analytics";
import "./styles/bundle.scss";
import './i18n';

import App from './app/App';

// Init firebase & analytics
firebase.initializeApp(CONFIG.FIREBASE);
firebase.analytics();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
