import React from 'react';

import products from "../../data/products.json";
import { shuffle } from "../utils/functions";
import ProductItem from "../pages/Home/components/ProductItem";

export function ProductSliderDesktop() {
  const lots = [...products];

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="product-slider owl-carousel">
          {shuffle(lots).map((item, idx) => <ProductItem key={idx} item={item} />)}
        </div>
      </div>
    </div>
  );
}

export function ProductSliderMobile() {
  const one = [...products].filter(p => p.type !== "kids");
  const kids = [...products].filter(p => p.type !== "one");

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="product-slider owl-carousel wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
            {shuffle(one).map((item, idx) => <ProductItem key={idx} item={item} />)}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-12">
          <div className="product-slider owl-carousel wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
            {shuffle(kids).map((item, idx) => <ProductItem key={idx} item={item} />)}
          </div>
        </div>
      </div>
    </>
  );
}
