import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();

  return (
    <section className="pelum-about-area" id="apropos">
      <div className="container">
        <div className="full-width">
          <div className="row">
            <div className="col-lg-6 d-none d-sm-block">
              <div className="about-left wow fadeInLeft" data-wow-delay="0.5s">
                <img src="assets/img/products/eventail-one.png" alt="about" className="animation-jump" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay="0.5s">
                <div className="about-heading">
                  <h4>{t("À propos")}</h4>
                  <h2>{t("Le nouveau coton-tige écolo qu’il vous faut !")}</h2>
                  {/* <h2>{t("Pourquoi Welbe EARS ?")}</h2> */}
                  <span className="heading_overlay"></span>
                </div>
                <p>
                  <Trans>
                    Les <strong>EARS de Welbe</strong> sont conseillés par les ORL et sont une alternative répondant
                    à l'interdiction de l'utilisation des cotons tiges, car jugés néfastes pour la santé auditive et
                    pour l'environnement.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    Les <strong>EARS de Welbe</strong> sont juste destinés à se débarrasser régulièrement de l'excès de
                    Cérumen (la substance sécrétée par l'oreille pour sa protection) en toute sécurité. C'est la raison
                    pour laquelle la taille de l'extrémité des EARS, ne permet pas d'atteindre le tympan qui
                    demeure un organe très sensible.
                  </Trans>
                </p>
                <p>
                  <Trans>Les <strong>EARS de Welbe</strong> sont utilisables plusieurs années et recyclables.</Trans>
                </p>
                {/* <ul>
                  <li><i className="fa fa-check"></i> Agréable au toucher</li>
                  <li><i className="fa fa-check"></i> En SEBS médical</li>
                  <li><i className="fa fa-check"></i> Écologique et économique</li>
                  <li><i className="fa fa-check"></i> Lavable et réutilisable pendant 5 ans.</li>
                </ul> */}
                <div className="about-slogan">
                  <h4>{t("Welbe vous aime, vous aimerez Welbe !")}</h4>
                </div>
                <div className="about-label">
                  <img src="assets/img/made-in-france.png" alt={t("Fabriqué en France")} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-block d-sm-none">
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay="0.5s">
                <img src="assets/img/products/eventail-one.png" alt="about" className="animation-jump" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
