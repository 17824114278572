import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function Video() {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <section className="pelum-video-area" id="demonstration">
      <div className="container">
        <div className="video-box">
          <div className="video-inn" style={{backgroundImage: "url(assets/img/video-demo.png)"}}>
            <img src="assets/img/video-demo.gif" alt="Démonstration du EARS" className="video-cover" />
            <div className="video-content">
              <div className="inner">
                <ModalVideo
                  autoplay
                  channel='youtube'
                  controls={false}
                  videoId="RBiuZuACTZo"
                  isOpen={isOpen}
                  onClose={() => setOpen(false)}
                />
                <div className="video-thumbsnail">
                  <img src="assets/img/video-demo.png" alt="Démonstration du EARS" />
                  <Link to="#demonstration" className="popup-youtube" onClick={() => setOpen(true)}>
                    <i className="flaticon-play"></i>
                  </Link>
                </div>
                <h2>{t("Le EARS est réutilisable agréable au toucher")}</h2>
                <span className="heading_overlay"></span>
                <p>
                  <Trans t={t}>
                    Grâce à ses 6 petites curettes aux normes médicales il permet de nettoyer
                    efficacement et agréablement les oreilles. Il retire tout le cérumen du
                    canal auditif. Contrairement au coton-tige classique, qui tasse la cire au
                    fond de votre oreille et fortement déconseillé par tous les ORL.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
