import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function usePage(title) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const navbar = document.querySelector(".navbar");

    if (navbar) {
      document.title = `${t(title)} - Welbe EARS`;
      navbar.classList.add("fixed-top");
    }
  }, [t, title]);
}
