import React from 'react';

const Preloader = () => {
	return (
		<div className="preloader">
			<div className="loader">
				<div className="shadow"></div>
        <div className="box">
          <img src="icon.svg" alt="Logo" />
        </div>
			</div>
		</div>
	);
};

export default Preloader;