import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { usePage } from "../../hooks";
import {AppNavbar as Navbar} from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function CheckoutResultPage() {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const state = params.get("state");
  const reference = params.get("reference");
  const title = (state === "success") ? "Votre commande a été confirmée" : "Votre commande a échouée";

  usePage(title);

  if (!params.has("state") || !params.has("reference")) {
    return <Redirect to="/boutique" />;
  }

  return (
    <>
      <Navbar withMenu={false} />
      <section className="app-page-area shop-checkout-page checkout-result">
        <div className={`header py-lg-5 py-4 ${state === "success" ? 'success' : 'error'}`}>
          <div className="container">
            <h1 style={{textAlign: 'center'}}>
              {state === "success" ? t("Commande validée") : t("La commande a échouée")}
            </h1>
          </div>
        </div>
        <div className="py-5">
          <div className="container">
            <picture className={`${state === "success" ? 'success' : 'error'}`}>
              <i className={`fa ${state === "success" ? 'fa-check' : 'fa-times'}`}></i>
            </picture>
            {state === "success" ? (
              <div className="result-message">
                <p>{t("Nous vous remercions pour vos achats sur le site Welbe!")}</p>
                <p>{t("Votre commande")} <strong>N° {reference}</strong> {t("a été confirmée avec succès.")}</p>
              </div>
            ) : (
              <div className="result-message">
                <p>{t("Votre commande")} <strong>N° {reference}</strong> {t("n'a pas été validée.")}</p>
                <p>{t("Nous vous invitons à relancer une nouvelle commande sur le site Welbe!")}</p>
              </div>
            )}
          </div>
        </div>
        <div className="pb-5 shop-checkout-actions">
          <div className="container">
            <Link to="/#accueil" className="btn btn-outline-primary">
              {t("Retour à l'accueil")}
            </Link>
            <Link to="/boutique" className="btn btn-primary">
              {t("Continuer vos achats")}
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
