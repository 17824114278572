import React, { createContext, useContext } from 'react';
import CONFIG from "../utils/config";

const AppContext = createContext(null);

export const AppProvider = (props) => {
  const context = {
    anchors: CONFIG.ANCHORS
  };

  return (
    <AppContext.Provider value={context}>
      { props.children }
    </AppContext.Provider>
  );
};

export function useApp() {
  return useContext(AppContext);
};
