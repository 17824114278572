import React, { useCallback } from "react";

import CONFIG from "../../utils/config";
import products from "../../../data/products.json";
import QuantitySwitcher from "./QuantitySwitcher";

const CartItem = ({ item, onDelete, onEdit }) => {
  const currency = CONFIG.CURRENCY;
  const [current] = products.filter(p => p.id === item.ref);

  const handleChange = useCallback(count => {
    onEdit(item.id, count)
  }, [item.id, onEdit]);

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="card-grid">
          <div className="grid-item image">
            <div className="picture" style={{backgroundImage: `url(${current.photoUrl})`}}></div>
          </div>
          <div className="grid-item info">
            <h4>{item.name}</h4>
          </div>
          <div className="grid-item price">
            {item.amount.toFixed(2) + currency}
          </div>
          <div className="grid-item quantity">
            <QuantitySwitcher onChange={handleChange} count={item.count} min={1} />
          </div>
          <div className="grid-item subtotal">
            {(item.amount * item.count).toFixed(2) + currency}
          </div>
          <div className="grid-item action">
            <button type="button"onClick={() => onDelete(item.id)}>
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
