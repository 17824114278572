import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

import CONFIG from "../utils/config";
import { useCart } from "../services/cart";
import { getCartAmount, getCartCount } from "../utils/functions";

const ShopNavbarCard = () => {
  const { cart } = useCart();
  const [order, setOrder] = useState({ count: 0, amount: 0 });

  useEffect(() => {
    const sub = cart.change().subscribe(cart => {
      const count = getCartCount(cart);
      const amount = getCartAmount(cart);

      setOrder({count, amount});
    });

    return () => sub.unsubscribe();
  }, [cart]);

  return (
    <Nav.Item as="li" className="shop-cart">
      <Nav.Link as={Link} to="/panier">
        <i className="fa fa-shopping-cart"></i> <span>{order.amount.toFixed(2)}{CONFIG.CURRENCY}</span>
      </Nav.Link>
      {order.count ? <div className="shop-cart-counter">{order.count}</div> : null}
    </Nav.Item>
	);
};

export default ShopNavbarCard;
