import React from "react";
import { useTranslation } from 'react-i18next';

import CONFIG from "../../utils/config";

export default function StoreProductItem({item, addToCart}) {
  const { t } = useTranslation();
  const currency = CONFIG.CURRENCY;

  return (
    <div className="card">
      <div className="card-body">
        <div className="prod-image">
          <img src={item.photoUrl} alt={item.name} />
        </div>
        <h4 className="prod-title">{item.name}</h4>
        <div className="prod-meta">
          {item.meta.map((meta, idx) => <p key={idx}>{t(meta)}</p>)}
        </div>
        <h2 className="prod-price">
          {Number(item.price).toFixed(2)}<small>{currency}</small>
        </h2>
        <button type="button" onClick={() => addToCart(item)} className="btn btn-lg btn-block btn-primary prod-button">
          <i className="fa fa-cart-plus"></i> {t("Ajouter au panier")}
        </button>
      </div>
    </div>
  );
}
