import React from 'react';

import { isMobile } from "../../utils/functions";
import { AppNavbar as Navbar } from "../../components/Navbar";

import HomeDesktop from "./HomeDesktop";
import HomeMobile from "./HomeMobile";

export default function HomePage() {
  return (
    <>
      <Navbar />
      {isMobile(navigator.userAgent) ? <HomeMobile /> : <HomeDesktop />}
    </>
  );
}
