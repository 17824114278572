import React from 'react'

export default function SocialFAB() {
  return (
    <div className="social-fab">
      <input type="checkbox" className="social-fab-button" id="triggerButton" />
      <label htmlFor="triggerButton">
        <i className="fa fa-share-alt"></i>
      </label>
      <a href="https://web.facebook.com/welbefr" target="blank" className="social-fab-item one">
        <div className="fa fa-facebook"></div>
      </a>
      <a href="https://instagram.com/welbefr?igshid=tlqhydgmz23l" target="blank" className="social-fab-item two">
        <div className="fa fa-instagram"></div>
      </a>
    </div>
  )
}
