import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePage } from "../../hooks";
import { useCart } from "../../services/cart";
import { shuffle } from "../../utils/functions";
import products from "../../../data/products.json";

import {AppNavbar as Navbar} from "../../components/Navbar";
import Footer from "../../components/Footer";

import StoreProductItem from "./ProductItem";
import StoreFilter from "./Filter";

export default function StorePage() {
  usePage("Achetez votre EARS");

  const { t } = useTranslation();
  const { addItem } = useCart();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    let filterd = []

    if (filter === "all") {
      filterd = products.filter(i => i.type !== filter);
    } else {
      filterd = products.filter(i => i.type === filter);
    }

    setItems(filterd);
  }, [filter]);

  const handleFilter = useCallback(value => setFilter(value), []);

  return (
    <>
      <Navbar />
      <section className="app-page-area shop-store-page">
        <div className="header py-lg-5 py-4">
          <div className="container">
            <h1>{t("Achetez votre EARS")}</h1>
          </div>
        </div>
        <div className="pt-5">
          <div className="container store-filter">
            <StoreFilter filter={filter} onChange={handleFilter} />
          </div>
        </div>
        <div className="py-5">
          <div className="container">
            <div className="product-grid">
              {shuffle(items).map((item, idx) => <StoreProductItem key={idx} item={item} addToCart={addItem} />)}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
