import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePage } from "../hooks";
import {AppNavbar as Navbar} from "../components/Navbar";
import Footer from "../components/Footer";

export default function PrivacyPage() {
  usePage("Confidentialité & Cookies");

  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section className="app-page-area">
        <div className="header py-lg-5 py-4">
          <div className="container">
            <h1>{t("Confidentialité & Cookies")}</h1>
          </div>
        </div>
        <div className="text-content pt-4">
          <div className="container">
            <p>La page suivante se découpe en 2 grandes parties</p>
            <ul>
              <li>L’utilisation des cookies sur le site web <a href="https://www.welbe.fr">www.welbe.fr</a></li>
              <li>Notre politique de confidentialité vis-à-vis des données personnelles collectées.</li>
            </ul>
            <p>Elle vous informera de ce qui suit</p>
            <ol>
              <li>La politique de cookies de www.welbe.fr</li>
              <li>Comment les informations personnellement identifiables sont recueillies par le biais du site Web,
                comment elles sont utilisées et avec qui elles peuvent être partagées.</li>
              <li>Les choix qui s’offrent à vous concernant l’utilisation de vos données.</li>
              <li>Les procédures de sécurité en place pour protéger l’utilisation abusive de vos informations.</li>
              <li>Comment corriger les inexactitudes dans les informations.</li>
            </ol>
            <h2>Qu’est-ce qu’un cookie?</h2>
            <p>
              Un cookie désigne un fichier texte que le site que vous visitez enregistre sur votre
              disque dur ou dans la mémoire de votre navigateur et qui permet à votre ordinateur de
              stocker différentes données techniques permettant le contrôle général de l’accès du
              public au site.Les informations stockées par les cookies, pendant une durée de validité
               imitée, portent notamment sur les pages visitées, le type de navigateur que vous utilisez,
               es informations que vous avez saisies sur un site afin de vous éviter de les saisir à
              nouveau.Ces informations sont parfois rattachées à votre adresse IP.
            </p>
            <h2>L’utilisation de cookies sur le site</h2>
            <p>
              L’équipe Welbe peut être amenée à installer divers «&nbsp; cookies&nbsp; » dans votre terminal pour
              collecter et enregistrer les informations concernant l’utilisation du site www.welbe.fr.
            </p>
            <p>
                        Les cookies sont conçus pour nous aider à vous reconnaître comme un précédent utilisateur du site afin de
              vous offrir le service le plus adapté.
            </p>
            <p>
              Les cookies que nous utilisons ne peuvent pas extraire de votre disque dur des informations personnelles
              permettant de vous identifier.
            </p>
            <h3>Les cookies nous permettent, par exemple:</h3>
            <ul>
              <li>
                D’établir des statistiques et volumes de fréquentation et d’utilisation des divers éléments composant
                notre
                site (rubriques et contenus visités, parcours) nous permettant d’améliorer l’intérêt et l’ergonomie de
                nos
                services; pour ce faire, nous pouvons utiliser Google Analytics ou tout autre similaire outil
                 ’analyse.
                L’information collectée est agrégée et ne permet pas une identification individuelle.
              </li>
            </ul>
            <p>
                            Pour en savoir plus sur Google Analytics :&nbsp;<a
                href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a>
            </p>
            <ul>
              <li>
                d’adapter la présentation de notre site aux préférences d’affichage de votre terminal lors de vos
                visites sur notre site, selon les matériels et les logiciels de visualisation ou de lecture que votre
                terminal comporte.
              </li>
              <li>
                de mettre en œuvre des mesures de sécurité, par exemple lorsqu’il vous est demandé de vous connecter à
                nouveau à un contenu ou à un service après un certain laps de temps.
              </li>
              <li>
                ou encore d’identifier les rubriques du site que vous avez visitées, de suivre l’évolution de vos
                préférences, ou de suivre votre cheminement à travers nos pages web.
              </li>
            </ul>
            <p>
                  Nous utilisons les cookies seulement pour recueillir les informations mentionnées dans cette Charte et, en
              aucune façon, des informations à caractère personnel ou nominatives permettant de vous identifier sans
              votre
              consentement.
            </p>
            <h3>Vous avez la possibilité d’intervenir pour gérer les cookies.</h3>
            <p>
                         Vous pouvez choisir d’accepter le dépôt de cookies sur votre terminal et revenir sur cette décision à tout
              moment en modifiant les paramètres de votre navigateur internet.<br />
              Selon le type de navigateur, vous disposerez des options suivantes&nbsp;: accepter ou rejeter les cookies
              de
              toute origine ou d’une provenance donnée ou encore programmer l’affichage d’un message vous demandant
              votre
              accord à chaque fois qu’un cookie est déposé sur votre terminal.<br />
              Pour exprimer ou revenir sur vos choix, reportez-vous au menu d’aide ou à la rubrique dédiée de votre
              navigateur.A titre d’exemple&nbsp;:
            </p>
            <ul>
              <li>
                <strong>Pour Chrome</strong> <a
                  href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en">https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en</a>
              </li>
              <li>
                <strong>Pour Internet Explorer</strong> &nbsp;<a
                  href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-Cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-Cookies</a>
              </li>
              <li>
                <strong>Pour FireFox</strong> &nbsp;<a
                  href="http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&amp;redirectslug=G%C3%A9rer+les+cookies">http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&amp;redirectslug=G%C3%A9rer+les+cookies</a>
              </li>
              <li>
                <strong>Pour Opéra</strong>&nbsp;&nbsp;<a
                  href="http://help.opera.com/Windows/10.20/fr/cookies.html">http://help.opera.com/Windows/10.20/fr/cookies.html</a>
              </li>
            </ul>
            <h3>Au sujet des cookies déposés par Google Analytics&nbsp;:</h3>
            <p>
              Vous pouvez avoir plus d’informations sur les cookies analytics, et notamment comment les désactiver, en
              suivant les liens ci-dessous:
            </p>
            <ul>
              <li>
                <a
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>
              </li>
              <li>
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=fr">https://tools.google.com/dlpage/gaoptout?hl=fr</a>
              </li>
            </ul>
            <p>https://www.welbe.fr conserve les cookies déposés par Google Analytics pour une durée de 14 mois.</p>
            <p>
              Enfin, vous pouvez vous connecter sur le site&nbsp;<a
                href="http://www.youronlinechoices.com">www.youronlinechoices.com</a>,
              créé par des associations de professionnels, et suivre les instructions proposées par ce site pour vous
              aider à contrôler, autoriser ou bloquer les cookies détenus dans votre terminal.
            </p>
            <h2>Les cookies émis sur www.welbe.fr sur notre site par des tiers.</h2>
            <p>
              L’émission et l’utilisation de cookies par des tiers, sont soumises aux politiques de protection
              de la vie privée de ces tiers.Les seuls cookies tiers déposés en navigant sur notre site sont
              ceux des réseaux sociaux.
            </p>
            <p>
              Ces réseaux sociaux déposent des cookies afin de vérifier si vous êtes connecté(e) à leurs services.
              Cela permet une utilisation plus fluide de leurs boutons de partage.
            </p>
            <h3>L’impact des réseaux sociaux</h3>
            <p>
              Le réseau social fournissant un tel bouton applicatif est susceptible de vous identifier grâce
              à ce bouton, même si vous n’avez pas utilisés ce bouton lors de votre consultation de notre site.
              En effet, ce type de bouton applicatif peut permettre au réseau social concerné de suivre votre
              navigation sur notre site, du seul fait que votre compte au réseau social concerné était activé
              sur votre terminal (session ouverte) durant votre navigation sur notre site.
            </p>
            <p>
              Nous n’avons aucun contrôle sur le processus employé par les réseaux sociaux pour collecter
              des informations relatives à votre navigation sur notre site et associées aux données
              personnelles dont ils disposent.Nous vous invitons à consulter les politiques de protection
               e la vie privée de ces réseaux sociaux afin de prendre connaissance des finalités d’utilisation,
               otamment publicitaires, des informations de navigation qu’ils peuvent recueillir grâce à ces
               outons applicatifs.Ces politiques de protection doivent notamment vous permettre d’exercer
                s choix auprès de ces réseaux sociaux, notamment en paramétrant vos comptes d’utilisation de
              chacun de ces réseaux.
            </p>
            <h2>Collecte, utilisation et partage d’informations</h2>
            <p>
              Welbe a uniquement accès aux informations données volontairement par courrier électronique, par
              formulaires ou par tout autre contact direct de votre part.Welbe ne demande que des informations
              nécessaires au traitement de la demande client.Ces informations ne seront ni vendues ni louées.
            </p>
            <p>
                            Ces informations seront utilisées uniquement pour répondre à vos questions.&nbsp; Elles ne seront en aucun               cas
              partagées avec un tiers en dehors de notre organisation, sauf pour expédier une commande.
            </p>
            <p>
              Sauf avis contraire de votre part, nous pourrons vous contacter par courriel afin de vous informer sur les
              offres spéciales, les nouveaux produits ou services ou les modifications apportées à cette politique de
              confidentialité.
            </p>
            <p>
              Dans le cadre de la démarchage marketing, de partage de nos ressources (notamment lors de téléchargements
              avec formulaire) et de l’envoi de mails, Welbe utilise MailChimp et Autopilot.Les données
               olontairement fournies y sont conservées pendant jusqu’à ce que vous ne soyez plus intéressé(e)s par nos
               ctivités et que vous nous le signaliez.Pour cela, chaque mail envoyé comporte une option pour se
              désinscrire.
            </p>
            <h2>Accès et contrôle des informations</h2>
            <p>
              Vous pouvez à tout moment vous désinscrire et/ou vous opposer à l’utilisation de vos informations.A tout
              moment, et en nous contactant par courriel ou par téléphone, vous pouvez&nbsp;:
            </p>
            <ul>
              <li>Connaître les données que nous avons collectées, le cas échéant.</li>
              <li>Modifier / corriger les données que nous avons sur vous.</li>
              <li>Demander de supprimer toutes les données que nous avons sur vous.</li>
              <li>Exprimer toute préoccupation concernant l’utilisation de vos données.</li>
            </ul>
            <h2>Sécurité</h2>
            <p>
              Toutes les précautions sont prises pour protéger vos informations.Toutes les informations soumises via
               otre
              site web sont protégées en ligne et hors ligne.
            </p>
            <p>
              Toutes les informations sensibles (telles que des données de carte de crédit), sont cryptées et transmises
              de
              manière sécurisée.Cela se traduit sur votre écran par la présence de l’icône du cadenas fermé ou via le
              «&nbsp; https&nbsp; » au début de l’adresse URL de la page web.
            </p>
            <p>
              L’ensemble des informations sont également protégées hors connexion.Seuls les employés qui ont besoin
               ’informations pour effectuer un travail spécifique (par exemple, la facturation ou le service à la
               lientèle) ont accès à ces informations.Les ordinateurs et serveurs dans lesquels nous stockons des
              informations personnelles sont conservés dans un environnement sécurisé.
            </p>
            <h2>Enregistrement</h2>
            <p>
              Afin d’utiliser certaines parties de ce site, l’utilisateur doit d’abord remplir un formulaire
              d’inscription.Pendant l’enregistrement, l’utilisateur est tenu de fournir certaines informations
               telles que le nom et l’adresse électronique).Ces informations sont utilisées pour vous
                ntacter au sujet des produits / services sur notre site pour lesquels vous avez manifesté votre
                térêt.Des informations complémentaires facultatives peuvent également être demandées
              (comme le sexe ou l’âge).
            </p>
            <h2>Partage</h2>
            <p>
                        Les informations démographiques peuvent faire l’objet d’un partage avec nos partenaires et annonceurs.En
              revanche, aucune information personnelle permettant d’identifier une personne ne sera transmise.
            </p>
            <h2>Liens</h2>
            <p>
              Ce site contient des liens vers d’autres sites.Veuillez noter que nous ne sommes pas responsables du
               ontenu
               u des pratiques de confidentialité des autres sites.Nous encourageons nos utilisateurs à lire les
                clarations de confidentialité de tout autre site qui recueille des informations personnelles
              identifiables.
            </p>
            <h2>Mises à jour</h2>
            <p>Notre politique de confidentialité peut évoluer.Toutes les mises à jour seront affichées sur cette page.
            </p>
            <p>
              <strong>
                Si vous estimez que nous ne respectons pas cette politique de confidentialité, vous devez nous
                contacter immédiatement par téléphone au 04 72 83 97 97 ou par courriel à info @welbe.fr.
              </strong>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
