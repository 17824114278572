import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <section className="pelum-contact-area" id="contact">
      <div className="container">
        <div className="full-width">
            <div className="row">
              <div className="col-lg-12">
                  <div className="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    <h4>{t("Contactez-nous")}</h4>
                    <h2>{t("Pour des suggestions")}</h2>
                    <span className="heading_overlay"></span>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                  <div className="contact-form wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                    <form>
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <p>
                              <input className="form-control" type="text" placeholder={t("Votre Nom")} required />
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <p>
                              <input className="form-control" type="email" placeholder={t("Adresse Email")} required />
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p>
                              <input className="form-control" type="tel" placeholder={t("Numéro de Téléphone")} />
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <p>
                              <input className="form-control" type="text" placeholder={t("Sujet")} />
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <p>
                              <textarea className="form-control" placeholder={t("Votre Message...")} required></textarea>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <p>
                              <button type="submit" className="pelum-btn">{t("Envoyer le message")}</button>
                            </p>
                          </div>
                        </div>
                    </form>
                  </div>
              </div>
              <div className="col-lg-5">
                  <div className="contact-info">
                    <div className="single-contact-info wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                        <div className="contact-info-icon">
                          <i className="fa fa-location-arrow"></i>
                        </div>
                        <div className="contact-info-text">
                          <h3>{t("Siège Social")}</h3>
                          <p>26 Rue des Terres Fortes 77600 Chanteloup en Brie Marne la vallée</p>
                        </div>
                    </div>
                    <div className="single-contact-info wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
                        <div className="contact-info-icon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <div className="contact-info-text">
                          <h3>{t("Service Clients")}</h3>
                          <p>+33 09 53 099 852</p>
                        </div>
                    </div>
                    <div className="single-contact-info wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.7s">
                        <div className="contact-info-icon">
                          <i className="fa fa-envelope"></i>
                        </div>
                        <div className="contact-info-text">
                          <h3>{t("Adresses Email")}</h3>
                          <p>contact@welbe.fr</p>
                          <p>commercial@welbe.fr</p>
                          <p>logistique@welbe.fr</p>
                        </div>
                    </div>
                    <div className="single-contact-info wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
                        <div className="contact-info-icon">
                          <i className="fa fa-money"></i>
                        </div>
                        <div className="contact-info-text">
                          <h3>{t("Capital")}</h3>
                          <p>15,000€</p>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};
