export function setSectionTitle(to, anchors) {
    switch (to) {
    case anchors[1]:
        document.title = "À propos - Welbe EARS";
        break;
    case anchors[2]:
        document.title = "Pourquoi choisir le EARS - Welbe EARS";
        break;
    case anchors[3]:
        document.title = "Démonstration - Welbe EARS";
        break;
    case anchors[4]:
        document.title = "Acheter votre EARS - Welbe EARS";
        break;
    case anchors[5]:
        document.title = "Témoignages de nos clients - Welbe EARS";
        break;
    case anchors[6]:
        document.title = "Contactez-nous - Welbe EARS";
        break;
    case anchors[7]:
        document.title = "FAQ - Welbe EARS";
        break;
    case anchors[8]:
        document.title = "Newsletter - Welbe EARS";
        break;
    default:
        document.title = "Welbe EARS - Le nouveau coton-tige réutilisable";
        break;
    }
}

export function isMobile(agent) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent);
}

export function getCartCount(cart) {
  return cart.reduce((a, b) => a + b.count, 0);
}

export function getCartAmount(cart) {
  return cart.reduce((a, b) => a + (b.amount * b.count), 0);
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }

  return a;
}
