import React from "react";
import { useTranslation } from 'react-i18next';

const fields = [
  {value: "all", label: "Tous"},
  {value: "one", label: "Pour adultes"},
  {value: "kids", label: "Pour enfants"}
];

function StoreFilter({filter, onChange}) {
  const { t } = useTranslation();

  const handle = (e) => onChange(e.target.value);
  const classNames = (a) => `btn btn-primary${filter === a ? " active" : ""}`;

  return (
    <div className="btn-group btn-group-toggle">
      {fields.map((item, idx) => (
        <label key={idx} className={classNames(item.value)}>
          <input type="radio" id="filter-btn" value={item.value} onChange={handle} checked={filter === item.value} /> {t(item.label)}
        </label>
      ))}
    </div>
  );
}

export default StoreFilter;
