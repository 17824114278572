import React from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from "../components/AccordionItem";

export default function Faq() {
  const { t } = useTranslation();
  return (
    <section className="pelum-faq-area darked-area secondary" id="faq">
      <div className="container">
        <div className="full-width">
          <div className="row">
            <div className="col-lg-6">
              <div className="faq-left">
                <div className="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                  <h4>FAQ</h4>
                  <h2>{t("Questions fréquentes")}</h2>
                  <span className="heading_overlay"></span>
                </div>
                <div className="accordion" id="accordionExample">
                  <AccordionItem
                    title={t("Comment utiliser le EARS ?")}
                    collapseId="collapseOne"
                    headingId="headingOne"
                    wowDelay="0.5s"
                  >
                    <p>
                      {t("On insère délicatement Le EARS dans l’oreille pour venir gratter délicatement les parois à l’entrée de l’orifice. Répéter le geste plusieurs fois pour récupérer le cérumen grâce aux curettes en SEBS. Puis nettoyer le sur une serviette de toilette ou sous un filet d’eau.")}
                    </p>
                  </AccordionItem>
                  <AccordionItem
                    title={t("Où acheter le EARS ?")}
                    collapseId="collapseTwo"
                    headingId="headingTwo"
                    wowDelay="0.7s"
                  >
                    <p>{t("Vous pouvez achetez votre EARS directement sur notre boutique ou en pharmacie.")}</p>
                  </AccordionItem>
                  <AccordionItem
                    title={t("Quels sont les différents modèles EARS proposés ?")}
                    collapseId="collapseThree"
                    headingId="headingThree"
                    wowDelay="0.9s"
                  >
                    <p>{t("Welbe EARS ONE (pour adultes)")}</p>
                    <p>{t("Welbe EARS KIDS (pour enfants)")}</p>
                  </AccordionItem>
                  <AccordionItem
                    title={t("Combien coûte le EARS ?")}
                    collapseId="collapseFour"
                    headingId="headingFour"
                    wowDelay="1.1s"
                  >
                    <p>{t("Un prix unique 9,90 euros.")}</p>
                  </AccordionItem>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-right wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.5s">
                <img src="assets/img/products/kids-shadow.png" alt="faqs" className="animation-jump" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
