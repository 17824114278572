import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import { usePage } from "../../hooks";
import { useCart } from "../../services/cart";
import {AppNavbar as Navbar} from "../../components/Navbar";
import Footer from "../../components/Footer";

import Adress from "./Adress";
import Order from "./Order";

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  location: "",
  city: "",
  postalCode: "",
  // region: "",
};

export default function CheckoutPage() {
  usePage("Finalisation de la commande");

  const { t } = useTranslation();
  const { cart } = useCart();
  const [items, setItems] = useState([]);
  const [init, setInit] = useState(false);
  const [valid, setValid] = useState(false);
  const [agree, setAgree] = useState(false);
  const [adress, setAdress] = useState(null);
  const { register, formState: { isValid, errors}, getValues, reset } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues
  });

  useEffect(() => {
    (async () => {
      const data = await cart.load();
      setItems(data);
      setInit(true);
    })();
  }, [cart]);

  useEffect(() => {
    let data = null;
    const orderValid = agree && isValid ? true : false;
    setValid(orderValid);

    if (isValid) {
      data = getValues();
    }

    setAdress(data);
  }, [agree, isValid, getValues]);

  const handleAgree = useCallback(agreeTerms => setAgree(agreeTerms), []);

  if (init && !items.length) {
    return <Redirect to="/boutique" />;
  }

  return (
    <>
      <Navbar withMenu={false} />
      <section className="app-page-area shop-checkout-page">
        <div className="header py-lg-5 py-4">
          <div className="container">
            <h1>{t('Finalisation de la commande')}</h1>
          </div>
        </div>
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <Adress register={register} errors={errors} />
              </div>
              <div className="col-md-4">
                <Order
                  items={items}
                  defaultValues={defaultValues}
                  reduction={{ code: null, amount: 0 }}
                  reset={reset} adress={adress}
                  onAgree={handleAgree}
                  disabled={!valid}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
