import React from 'react';
import { useTranslation } from 'react-i18next';

import { isMobile } from "../../../utils/functions";
import { ProductSliderDesktop, ProductSliderMobile } from "../../../components/ProductSlider";

export default function ShopSection() {
  const { t } = useTranslation();

  return (
    <>
      <section className="pelum-product-area" id="acheter">
        <div className="container">
          <div className="full-width">
            <div className="row">
              <div className="col-lg-12">
                <div className="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                  <h4>{t("Acheter votre EARS")}</h4>
                  <h2>{t("Disponible en 2 versions et 8 couleurs")}</h2>
                  <span className="heading_overlay"></span>
                </div>
              </div>
            </div>
            {isMobile(navigator.userAgent) ? <ProductSliderMobile /> : <ProductSliderDesktop />}
          </div>
        </div>
      </section>
    </>
  );
}
