import React, { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import firebase from "firebase/app";

import CONFIG from "../../utils/config";
import { usePage } from "../../hooks";
import { useCart } from "../../services/cart";
import { getCartAmount, getCartCount } from "../../utils/functions";
import {AppNavbar as Navbar} from "../../components/Navbar";
import Footer from "../../components/Footer";

import CartItem from "./CartItem";

export default function CartPage() {
  usePage("Panier");

  const currency = CONFIG.CURRENCY;

  const { t } = useTranslation();
  const { cart } = useCart();
  const [order, setOrder] = useState({ count: 0, amount: 0 });
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await cart.load();
      setItems(data);
    })();
  }, [cart]);

  useEffect(() => {
    const count = getCartCount(items);
    const amount = getCartAmount(items);

    setOrder({count, amount});
  }, [items]);

  const deleteItem = useCallback(id => {
    const remaining = items.filter(i => id !== i.id);
    cart.save(remaining).then(d => {
      setItems(d);
      firebase.analytics().logEvent("remove_from_cart", {productId: id});
    });
  }, [items, setItems, cart]);

  const editItem = useCallback((id, q) => {
    const edited = items.map(i => (i.id === id) ? { ...i, count: q } : i);
    cart.save(edited).then(d => setItems(d));
  }, [items, setItems, cart]);

  return (
    <>
      <Navbar />
      <section className="app-page-area shop-cart-page">
        <div className="header py-lg-5 py-4">
          <div className="container">
            <h1>
              {items.length ? `${t("Panier")} (${order.count} ${order.count <= 1 ? t("article") : t("articles")})` : t("Panier")}
            </h1>
          </div>
        </div>
        {items.length ? (
          <div className="py-5 shop-cart-list">
            <div className="container">
              <div className="title-grid">
                <div className="grid-item info">
                  <h4>{t("Article")}</h4>
                </div>
                <div className="grid-item price">
                  <h4>{t("Prix unitaire")}</h4>
                </div>
                <div className="grid-item quantity">
                  <h4>{t("Quantité")}</h4>
                </div>
                <div className="grid-item subtotal">
                  <h4>{t("Sous-total")}</h4>
                </div>
              </div>
              {items.map((item, idx) => <CartItem key={idx} item={item} onDelete={deleteItem} onEdit={editItem} />)}
              <div className="py-3 total">
                {t("Total")}: <span>{order.amount.toFixed(2) + currency}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-5 shop-cart-empty">
            <div className="container">
              <picture>
                <i className="fa fa-shopping-cart"></i>
              </picture>
              <h3>{t("Votre panier est vide !")}</h3>
              <p>{t("Explorez notre boutique et découvrez nos meilleures produits!")}</p>
            </div>
          </div>
        )}
        <div className="pb-5 shop-cart-actions">
          <div className="container">
            {items.length ? <>
              <Link to="/boutique" className="btn btn-outline-primary">
                {t("Retour à la boutique")}
              </Link>
              <Link to="/commande" className="btn btn-primary">
                {t("Finaliser votre commande")}
              </Link>
            </> : (
              <Link to="/boutique" className="btn btn-primary">
                {t("Commencez vos achats")}
              </Link>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
