import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TestimonialItem({item}) {
  const { t } = useTranslation();

	return (
		<div className="single-testimonial">
		  <div className="testimonial-text">
		    <p>{t(item.message)}</p>
		    <span><i className="fa fa-quote-right"></i></span>
		  </div>
		  <div className="testimonial-image">
		    <img src={item.photoUrl} alt={item.name} className="img-fluid" />
		  </div>
		  <div className="testimonial-meta">
		    <h4 className="client-title">{item.name}</h4>
		    <p className="client-company">{t(item.location)}</p>
		  </div>
		</div>
	);
};
