import React, { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import * as WOW from 'wow.js';

import { useApp } from "../../services/app";

import Welcome from "./sections/Welcome";
import About from "./sections/About";
import Choose from "./sections/Choose";
import Video from "./sections/Video";
import Testimonial from "./sections/Testimonial";
import Contact from "./sections/Contact";
import Faq from "./sections/Faq";
import Bottom from "./sections/Bottom";
import ShopSection from "./sections/Shop";

import { setSectionTitle } from "../../utils/functions";

export default function HomeDesktop() {
  const context = useApp();
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const navbar = document.querySelector(".navbar");

    if (fixed) {
      navbar.classList.add("fixed-top");
    } else {
      navbar.classList.remove("fixed-top");
    }
  }, [fixed]);

  return (
    <ReactFullpage
      {...{
        anchors: context.anchors,
        scrollBar: true,
        lockAnchors: true,
        controlArrows: false,
        verticalCentered: false,
        afterRender: function () {
          new WOW().init();
        },
        afterLoad: function (origin, to) {
          setSectionTitle(to.anchor, context.anchors);
          setFixed(() => to.index !== 0);
        }
      }}
      render={({ fullpage }) => (
        <ReactFullpage.Wrapper>
          <div className="section">
            <Welcome />
          </div>
          <div className="section">
            <About />
          </div>
          <div className="section">
            <Choose />
          </div>
          <div className="section">
            <Video />
          </div>
          <div className="section">
            <ShopSection />
          </div>
          <div className="section">
            <Testimonial />
          </div>
          <div className="section">
            <Contact />
          </div>
          <div className="section">
            <Faq />
          </div>
          <div className="section app-section-footer">
            <Bottom />
          </div>
        </ReactFullpage.Wrapper>
      )} />
  );
}
