import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { BehaviorSubject } from 'rxjs';
import firebase from "firebase/app";
import Swal from 'sweetalert2';

import { Alert } from "./alert";
import { useStorage } from "./storage";
import PackModal from "../components/PackModal";

const DB_KEY = "welbestore_cart";
const CartContext = createContext(null);

class CartService {
  constructor(storage) {
    this.storage = storage;
    this.state = new BehaviorSubject([]);

    this.init();
  }

  change() {
    return this.state.asObservable();
  }

  async init() {
    let data = [];

    if (await this.storage.hasItem(DB_KEY)) {
      data = await this.storage.getItem(DB_KEY);
      this.state.next(data);
    } else {
      this.storage.setItem(DB_KEY, data);
    }
  }

  async load() {
    return await this.storage.getItem(DB_KEY);
  }

  async add(prod) {
    let stack = [];
    let exist = false;

    if (await this.storage.hasItem(DB_KEY)) {
      stack = await this.storage.getItem(DB_KEY);
      const items = [...stack].filter(i => i.ref === prod.ref);
      exist = items.length ? true : false;
    }

    if (exist && typeof prod.pack === "undefined") {
      stack = stack.map(i => (i.id === prod.id) ? { ...i, count: i.count + 1 } : i);
    } else {
      stack = [...stack, prod];
    }

    return this.storage.setItem(DB_KEY, stack).then(data => {
      firebase.analytics().logEvent("add_to_cart", {productId: prod.id});
      this.state.next(data);
      return data;
    });
  }

  async save(cart) {
    const data = await this.storage.setItem(DB_KEY, cart);
    this.state.next(data);

    return data;
  }

  async clear() {
    await this.storage.clear();
    this.state.next(null);
  }
}

export function CartProvider(props) {
  const { t } = useTranslation();
  const storage = useStorage();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const cart = useMemo(() => new CartService(storage), [storage]);

  const packModal = useMemo(() => ({
    show: () => setModalShow(true),
    hide: () => setModalShow(false),
  }), []);

  const addProduct = useCallback(prod => {
    cart.add(prod).then(() => {
      Alert.present({
        title: prod.name,
        text: t('Ajouté à votre panier'),
        confirmButtonText: t('Poursuivre vos achats'),
        cancelButtonText: t('Voir le panier'),
        showCancelButton: true,
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          history.push('panier');
        }
      });
    });
  }, [cart, history, t]);

  const addItem = useCallback(item => {
    const prod = {
      id: new Date().getTime(),
      ref: item.id,
      name: item.name,
      amount: Number(item.price),
      count: 1,
    };

    (item.type !== "multi") ? addProduct(prod) : packModal.show();
  }, [addProduct, packModal]);

  const context = {
    cart,
    packModal,
    addItem,
    addProduct,
  };

  return (
    <CartContext.Provider value={context}>
      <PackModal
        visible={modalShow}
        onClose={() => setModalShow(false)}
        onSubmitChoice={addProduct}
      />
      { props.children }
    </CartContext.Provider>
  );
};

export function useCart() {
  return useContext(CartContext);
};
