import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from "./pages/Home/Home";
import TermsPage from "./pages/Terms";
import PrivacyPage from "./pages/Privacy";
import StorePage from "./pages/Store/Store";
import CartPage from "./pages/Cart/Cart";
import CheckoutRouting from "./pages/Checkout/CheckoutRouting";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/confidentialite-cookies">
          <PrivacyPage />
        </Route>
        <Route path="/termes-conditions">
          <TermsPage />
        </Route>
        <Route path="/boutique">
          <StorePage />
        </Route>
        <Route path="/commande">
          <CheckoutRouting />
        </Route>
        <Route path="/panier">
          <CartPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
