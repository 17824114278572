import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import CONFIG from "../../../utils/config";
import { useCart } from "../../../services/cart";

export default function ProductItem({ item }) {
  const { t } = useTranslation();
  const { addItem } = useCart();
  const currency = CONFIG.CURRENCY;


	return (
		<div className="product-item">
		  <div className="row">
		    <div className="product-image col-lg-6">
		      <img src={item.photoUrl} alt={item.name} />
		    </div>
		    <div className="product-text col-lg-6">
		      <h3><Link to="#">{item.name}</Link></h3>
          <div className="product-meta">
            {item.meta.map((meta, idx) => <p key={idx}>{t(meta)}</p>)}
		      </div>
          <h4 className="pelum-price">
            {Number(item.price).toFixed(2)}<small>{currency}</small>
          </h4>
          <button type="button" onClick={() => addItem(item)} className="pelum-btn">
            {t("Ajouter au panier")}
          </button>
		    </div>
		  </div>
		</div>
	);
};
