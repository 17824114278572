import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="app-footer-area">
      <div className="container">
        <div className="row align-items-center no-gutters py-2 py-md-2">
          <div className="col-md-6 col-12">
            <span className="copyright">
              &copy; Welbe {new Date().getFullYear()}. {t("Tous droits réservés - Conçu par SYL")}
            </span>
          </div>
          <div className="col-12 col-md-6">
            <nav className="nav justify-content-center justify-content-md-end align-right">
              <Link className="nav-link" to="/confidentialite-cookies">{t("Confidentialité & Cookies")}</Link>
              <Link className="nav-link" to="/termes-conditions">{t("Termes & Conditions")}</Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
