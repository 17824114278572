import React from "react";

const QuantitySwitcher = ({ onChange, count, min, max }) => {
  count = (typeof count !== "undefined") ? count : 0;
  min = (typeof min !== "undefined") ? min : 0;

  const increment = () => onChange(count + 1);
  const decrement = () => onChange(count - 1);

  const handle = (e) => {
    const value = Number(e.target.value);
    onChange(value)
  };

  const inputStyle = {
    flex: "unset",
    width: "45px",
    textAlign: "center"
  };

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <button type="button" className="btn btn-sm btn-secondary" onClick={decrement} disabled={count === min}>-</button>
      </div>
      <input type="number" inputMode="numeric" min={min} pattern="[0-9]*" className="form-control form-control-sm" style={inputStyle} onChange={handle} value={count} />
      <div className="input-group-append">
        <button type="button" className="btn btn-sm btn-secondary" onClick={increment} disabled={max && count === max}>+</button>
      </div>
    </div>
  );
};

export default QuantitySwitcher;
