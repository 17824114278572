import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import { useApp } from "../services/app";
import CONFIG from "../utils/config";

import ShopNavbarCard from "./NavbarCart";

export function AppNavbar({ withMenu }) {
  if (typeof withMenu === "undefined") {
    withMenu = true;
  }

  const collapse = useRef(null);
  const toggler = useRef(null);
  const app = useApp();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [language, setLanguage] = useState(currentLang);
  const initial = window.location.hash;
  const [anchor, setAnchor] = useState(initial.slice(1));
  const anchors = app.anchors;

  const handleHashChange = (event) => {
    const hash = window.location.hash;
    setAnchor(hash.slice(1));
  };

  useEffect(() => {
    handleHashChange(null);
    window.addEventListener("hashchange", handleHashChange, false);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleLanguage = (lang) => {
    setLanguage(lang);
    toggler.current?.classList.add('collapsed');
    collapse.current?.classList.remove('show');
  };

  const renderLanguageMenu = () => {
    if (language === 'fr') {
      return (
        <>
          <img src="assets/img/fr.svg" alt="" width={20} /> Français
        </>
      );
    }

    return (
        <>
          <img src="assets/img/en.svg" alt="" width={20} /> English
        </>
      );
  };

  const renderMenu = () => (
    <>
      <Navbar.Toggle ref={toggler} aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse ref={collapse} id="responsive-navbar-nav">
        <Nav as="ul" className="ml-auto">
          <Nav.Item as="li">
            <Nav.Link href={`/#${anchors[0]}`} active={anchors[0] === anchor}>{t('Accueil')}</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item as="li">
            <Nav.Link href={`/#${anchors[1]}`} active={anchors[0] === anchor}>{t('À propos')}</Nav.Link>
          </Nav.Item> */}
          <Nav.Item as="li">
            <Nav.Link as={Link} to="/boutique">{t('Acheter')}</Nav.Link>
            {/* <Nav.Link href={`/#${anchors[4]}`} active={anchors[0] === anchor}>{t('Acheter')}</Nav.Link> */}
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href={`/#${anchors[6]}`} active={anchors[0] === anchor}>{t('Contact')}</Nav.Link>
          </Nav.Item>
          <NavDropdown id="collasible-nav-dropdown" title={renderLanguageMenu()}>
            {language !== "fr" ? (
              <NavDropdown.Item onClick={() => handleLanguage('fr')}>
                <img src="assets/img/fr.svg" alt="" width={20} /> Français
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item onClick={() => handleLanguage('en')}>
                <img src="assets/img/en.svg" alt="" width={20} /> English
              </NavDropdown.Item>
            )}
          </NavDropdown>
          <ShopNavbarCard />
        </Nav>
      </Navbar.Collapse>
    </>
  );

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand className="logo" href="/#accueil">
          <img src="logo.png" alt={CONFIG.APP_NAME} />
        </Navbar.Brand>
        <Navbar.Brand className="logo HideScroll" href="/#accueil">
          <img src="logo.png" alt={CONFIG.APP_NAME} />
        </Navbar.Brand>
        {withMenu ? renderMenu() : (
          <div className="hdr--rgt">
            <i className="fa fa-shield color-primary"></i>
            <div className="color-white">{t('Paiement sécurisé')}</div>
          </div>
        )}
      </Container>
    </Navbar>
  );
}
