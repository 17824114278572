import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

import en from './locales/en.json';

const langDetectorOptions = {
  order: ['localStorage', 'cookie', 'navigator'],
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  checkWhitelist: true,
};

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: { translation: {} },
      en: { translation: en }
    },
    fallbackLng: 'fr',
    whitelist: ['fr', 'en'],
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    detection: langDetectorOptions,
  });

export default i18n;
