import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default function Welcome() {
  const { t } = useTranslation();

	return (
		<section className="pelum-welcome-area fix" id="accueil">
			<div className="shapes-container">
				<div className="bg-shape"></div>
			</div>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 col-md-7">
						<div className="welcome-text">
							<h2><Trans>EARS de Welbe</Trans></h2>
							<h3><Trans>Le nouveau coton-tige réutilisable</Trans></h3>
							<p>
                <Trans>
                  Nettoyer ses oreilles est l’un des actes essentiels de notre hygiène corporelle. Le cure-oreille est l’un des ustensiles dédiés pour ce type de soin. Avec le <strong>EARS de Welbe</strong> plus de risque de se blesser avec un coton-tige.
                </Trans>
							</p>
              <a href="#apropos" className="pelum-btn">{t("Plus d'infos")}</a>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="welcome-image">
							<img src="assets/img/products/ears.png" alt="welcome" className="animation-jump" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
