import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Wave from "../../../components/Wave";

export default function Footer() {
  const { t } = useTranslation();

	return (
		<>
			<section className="pelum-subscribe-area" id="newsletter">
				<div className="container">
					<div className="row">
					<div className="col-lg-12">
						<div className="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
							<h4>Newsletter</h4>
							<h2>{t("Abonnez-vous pour recevoir nos offres")}</h2>
							<span className="heading_overlay"></span>
						</div>
					</div>
					</div>
					<div className="row">
					<div className="col-lg-12">
						<div className="subscribe-box wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
							<form>
								<input className="form-control" type="email" placeholder={t("Votre adresse email")} />
								<i className="fa fa-envelope"></i>
								<button className="pelum-btn" type="submit">{t("S'abonner")}</button>
							</form>
						</div>
					</div>
					</div>
				</div>
      </section>

			<footer className="pelum-bottom-area">
				<div className="app-footer-shape">
					<Wave />
				</div>
				<div className="app-footer-grid">
				  <div className="container">
						<div className="footer-grid-inner">
              <div className="copyright">
                &copy; Welbe {new Date().getFullYear()}. {t("Tous droits réservés - Conçu par SYL")}
              </div>
              <div className="terms">
                <Link to="/confidentialite-cookies">
                  <span>{t("Confidentialité & Cookies")}</span>
                </Link>
                <Link to="/termes-conditions">
                  <span>{t("Termes & Conditions")}</span>
                </Link>
              </div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};
