import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SweetAlert = withReactContent(Swal);
const defaultConfig = {
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary btn-margin-right',
    cancelButton: 'btn btn-outline-primary',
  }
};

const Loader = {
  present: (message = "Chargement...") => {
    return SweetAlert.fire({
      allowOutsideClick: false,
      html: `
        <div class="app-loader">
          <div class="loader-inner ball-pulse-sync">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="loader-message">
            <p>${message}</p>
          </div>
        </div>
      `,
      showConfirmButton: false,
    });
  },
  dismiss: () => Swal.close()
};

const Alert = {
  present: (message) => {
    let config = typeof message === "string" ? { text: message } : message;
    config = Object.assign({}, config, defaultConfig)

    return SweetAlert.fire(config);
  },
  dismiss: () => Swal.close(),
};

export {Alert, Loader};
