import React, { createContext, useContext } from 'react';

const StorageContext = createContext(null);

export default class StorageService {
  async getItem(key) {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  async setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  }

  async removeItem(key) {
    const data = localStorage.getItem(key);
    localStorage.removeItem(key);

    return JSON.parse(data);
  }

  async hasItem(key) {
    let exist = await this.getItem(key);
    return exist ? true : false;
  }

  async clear() {
    localStorage.clear();
  }
}

export const StorageProvider = (props) => {
  const value = new StorageService();

  return (
    <StorageContext.Provider value={ value }>
      { props.children }
    </StorageContext.Provider>
  );
};

export function useStorage() {
  return useContext(StorageContext);
};
