import React, { useEffect } from 'react';

import { AppProvider } from "./services/app";
import { CartProvider } from "./services/cart";
import { StorageProvider } from "./services/storage";
import Preloader from "./components/Preloader";
import SocialFAB from "./components/SocialFAB";

import Navigation from "./Navigation";

export default function App() {
  const hidePreloader = (e) => {
    document.querySelector('.preloader').classList.add('preloader-deactivate');
  };

  useEffect(() => {
    window.addEventListener('load', hidePreloader, false);
    return () => window.removeEventListener('load', hidePreloader);
  }, []);

  return (
    <>
      <Preloader />
      <SocialFAB />
      <AppProvider>
        <StorageProvider>
          <CartProvider>
            <Navigation />
          </CartProvider>
        </StorageProvider>
      </AppProvider>
    </>
  );
}
