import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import products from "../../data/products.json";

export default function PackModal({visible, onClose, onSubmitChoice}) {
  const { t } = useTranslation();

  const items = products.filter(i => i.type !== "multi");
  const labelSyle = {
    fontWeight: '700', fontSize: 12, textTransform: "uppercase"
  };
  const initialForm = {
    choice1: null,
    choice2: null,
    choice3: null,
  };

  const [valid, setValid] = useState(false);
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    let formValid = false;

    if (
      form.choice1 !== null &&
      form.choice2 !== null &&
      form.choice3 !== null
    ) {
      formValid = true;
    }

    setValid(formValid);
  }, [form]);

  const handleControl = (event) => {
    const control = event.target;
    const stack = products.filter(i => i.id === control.value);
    const value = stack.length ? stack[0] : null;

    setForm(state => ({ ...state, [control.name]: value }));
  };

  const handleHide = () => {
    setValid(false);
    setForm(initialForm);
  };

  const handleSubmit = () => {
    const [item] = products.filter(i => i.type === "multi");
    const prod = {
      id: new Date().getTime(),
      ref: item.id,
      name: item.name,
      amount: Number(item.price),
      count: 1,
      pack: Object.values(form)
    };

    onSubmitChoice(prod);
    onClose();
  };

  return (
    <Modal
      centered
      size="sm"
      show={visible}
      backdrop="static"
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Pack Famille")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="packForm.Choice1">
            <Form.Label style={labelSyle}>{t("Choix")} 1</Form.Label>
            <Form.Control name="choice1" as="select" onChange={handleControl}>
              <option>{t("Choisisser un produit...")}</option>
              {items.map((item, idx) => (
                <option key={idx} value={item.id}>{item.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="packForm.Choice2">
            <Form.Label style={labelSyle}>{t("Choix")} 2</Form.Label>
            <Form.Control name="choice2" as="select" onChange={handleControl}>
              <option>{t("Choisisser un produit...")}</option>
              {items.map((item, idx) => (
                <option key={idx} value={item.id}>{item.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="packForm.Choice3">
            <Form.Label style={labelSyle}>{t("Choix")} 3</Form.Label>
            <Form.Control name="choice3" as="select" onChange={handleControl}>
              <option>{t("Choisisser un produit...")}</option>
              {items.map((item, idx) => (
                <option key={idx} value={item.id}>{item.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onClose}>{t("Annuler")}</Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!valid}>
          {t("Valider")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
