import React, { useEffect } from 'react';
import * as WOW from 'wow.js';

import Welcome from "./sections/Welcome";
import About from "./sections/About";
import Features from "./sections/Features";
import Video from "./sections/Video";
import Testimonial from "./sections/Testimonial";
import Contact from "./sections/Contact";
import Faq from "./sections/Faq";
import Bottom from "./sections/Bottom";
import ShopSection from "./sections/Shop";

export default function HomeMobile() {
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    const sticky = navbar.offsetTop;

    const handleScroll = (event) => {
      if (window.pageYOffset > sticky) {
        navbar.classList.add("fixed-top");
      } else {
        navbar.classList.remove("fixed-top");
      }
    };

    new WOW().init({ live: false });

    window.addEventListener("scroll", handleScroll, false);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Welcome />
      <About />
      <Features />
      <Video />
      <ShopSection />
      <Testimonial />
      <Contact />
      <Faq />
      <Bottom />
    </>
  );
}
