import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import firebase from "firebase/app";

import CONFIG from "../../utils/config";
import { useCart } from "../../services/cart";
import { getCartAmount, getCartCount } from "../../utils/functions";

export default function Order ({ defaultValues, reset, adress, items, reduction, disabled, onAgree }) {
  const analytics = firebase.analytics();
  const { cart } = useCart();
  const { t, i18n } = useTranslation();

  const API_URL = process.env.REACT_APP_API;
  const currency = CONFIG.CURRENCY;
  const shipping = CONFIG.SHIPPING_AMOUNT;

  const [order, setOrder] = useState({ count: 0, amount: 0 });

  useEffect(() => {
    const count = getCartCount(items);
    const amount = getCartAmount(items);
    setOrder({count, amount});
  }, [items]);

  const handleAgree = e => onAgree(e.target.checked);

  const handleSubmit = (event) => {
    event.preventDefault();

    const paymentInfo = {
      currency,
      items,
      payment_type: "credit_card",
      value: order.amount,
    };

    cart.clear().then(() => reset(defaultValues));
    analytics.logEvent("add_payment_info", paymentInfo);
    event.target.submit();
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">{t('Votre commande')} <small>({order.count} {order.count === 1 ? t("article") : t("articles")})</small></h4>
      </div>
      <div className="card-body">
        <ul className="checkout-order-list">
          <li className="leader title">{t('Produit')} <span>{t('Montant')}</span></li>
          {items.map((item, idx) =>
            <li key={idx}>
              {item.name} <small>x {item.count}</small>
              <span>{(item.amount * item.count).toFixed(2) + currency}</span>
              {item.pack && (
                <ol>
                  {item.pack.map((choice, idx) =>
                    <li key={idx}>{choice.name}</li>
                  )}
                </ol>
              )}
            </li>
          )}
          <li>&nbsp;</li>
          <li className="leader">{t('Sous-total')} <span>{order.amount.toFixed(2) + currency}</span></li>
          {reduction.amount ? <li className="leader">{t('Réduction')} <span>-{reduction.amount.toFixed(2) + currency}</span></li> : null}
          <li className="leader">{t('Livraison')} <span>{shipping !== 0 ? shipping.toFixed(2) + currency : t("Gratuite")}</span></li>
          <li className="leader total">{t('Total')} <span>{((order.amount + shipping) - reduction.amount).toFixed(2) + currency}</span></li>
        </ul>
        <div className="py-2" style={{lineHeight: "18px"}}>
          <small>
            <Trans>Vos données personnelles seront utilisées pour traiter votre commande, soutenir
            votre expérience sur ce site Web et à d'autres fins décrites dans notre</Trans> <Link to="/confidentialite-cookies">{t("politique de confidentialité")}</Link>.
          </small>
        </div>
        <div className="custom-control custom-checkbox mt-4">
          <input type="checkbox" className="custom-control-input" id="agreeTerms" onChange={handleAgree} />
          <label className="custom-control-label" htmlFor="agreeTerms" style={{fontSize: "13px", fontWeight: 800, lineHeight: 1.6}}>
            <strong>{t("J'ai lu et j'accepte les")} <Link to="/termes-conditions">{t("termes et conditions")}</Link> {t("du site.")}</strong>
          </label>
        </div>
      </div>
      <div className="card-footer">
        <form method="post" onSubmit={handleSubmit} target="_top" action={`${API_URL}/checkout`}>
          <input type="hidden" name="language" value={i18n.language.toUpperCase()} />
          <input type="hidden" name="adress" value={adress ? JSON.stringify(adress) : ""} />
          <input type="hidden" name="order" value={items ? JSON.stringify(items) : ""} />
          <button type="submit" className="btn btn-primary btn-block" disabled={disabled}>
            {t("Continuer")}
          </button>
        </form>
      </div>
    </div>
  );
};
