import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePage } from "../hooks";
import {AppNavbar as Navbar} from "../components/Navbar";
import Footer from "../components/Footer";

export default function TermsPage() {
  usePage("Termes & Conditions");

  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <section className="app-page-area">
        <div className="header py-lg-5 py-4">
          <div className="container">
            <h1>{t("Termes & Conditions")}</h1>
          </div>
        </div>
        <div className="text-content pt-4">
          <div className="container">
            <p><strong>La dernière mise à jour des présentes Conditions Générales de Vente et d’Utilisation date du <code>5 décembre 2020</code>.</strong></p>
            <p>Les présentes Conditions Générales de Vente et d'Utilisation (le « Contrat ») régissent les rapports entre :</p>
            <p>Tout internaute qui s’inscrit au site internet <a href="https://www.welbe.fr">https://www.welbe.fr</a> (ci-après dénommée « l’Utilisateur »)</p>
            <p>D'une part,</p>
            <p>Et</p>
            <p>L’entrepreneur Stive Allouche (ci-après dénommée « Welbe »)</p>
            <p>D'autre part,</p>
            <p>Ci-après désignés individuellement « la Partie » ou « les Parties ».</p>
            <p>Welbe propose la vente de produits en ligne qui sont disponible à l’adresse internet suivante <a href="https://www.welbe.fr">https://www.welbe.fr</a> (« la Plateforme »).</p>

            <h2>Article 1 : Objet du Contrat</h2>
            <p>Le Contrat a pour objet de définir les modalités d’accès et d'utilisation de la Plateforme par l’Utilisateur.</p>
            <p>Tout Utilisateur souhaitant accéder à la Plateforme doit avoir préalablement consulté le Contrat.</p>
            <p>En outre, lors de son inscription, l'Utilisateur est invité à accepter le Contrat en cochant la case prévue à cet effet.<br />Si l'Utilisateur refuse de se conformer à l'une quelconque des obligations et conditions contenues dans le Contrat, il doit renoncer à accéder à la plateforme éditée par Welbe et à l’utiliser.</p>
            <p>L'Utilisateur déclare être majeur, ou être un mineur émancipé, ou, si ce n'est pas le cas, avoir obtenu le consentement de ses parents ou de ses représentants légaux pour accéder à la Plateforme et à l’utiliser.</p>
            <p>Welbe se réserve le droit de modifier à tout moment le Contrat.</p>
            <p>Toute modification prendra effet immédiatement à compter de la mise en ligne de la nouvelle version du Contrat sur la Plateforme.</p>
            <p>L'Utilisateur s'engage donc à consulter régulièrement le Contrat pour prendre connaissance des modifications y ayant été apportées.</p>
            <p>L'Utilisateur est libre de se désinscrire et d’arrêter tout utilisation du site édité par Welbe si le Contrat modifié ne lui convient pas. A défaut, il sera réputé accepter sans réserve la nouvelle version du Contrat.</p>

            <h2>Article 2 : Inscription à la Plateforme</h2>
            <p>Pour suivre la formation en ligne payante, l’Utilisateur devra s'inscrire en créant un compte sur la Plateforme.</p>
            <p>L'Utilisateur pourra s’inscrire à la Plateforme en remplissant manuellement les champs du formulaire d'inscription, à l'aide d'informations complètes et exactes.</p>
            <p>L'identifiant et le mot de passe définis par l’Utilisateur seront strictement personnels et confidentiels et l'Utilisateur devra les conserver et les utiliser de manière à en préserver la stricte confidentialité.</p>
            <p>Pour finaliser l’inscription, l’Utilisateur procédera au paiement de la formation au prix indiqué en dollars américains (US$), toutes taxes comprises au moment de l’inscription qui vaut acceptation de l’offre de Welbe selon le présent Contrat. Welbe accusera réception par envoi d’un email de validation du paiement et de la finalisation de l’inscription.</p>
            <p>Le coût des services de communication électroniques requis pour accéder à la Plateforme restent à la charge de l’Utilisateur.</p>
            <p>L’Utilisateur sera seul autorisé à accéder à la Plateforme à l'aide de son identifiant et son mot de passe. Toute utilisation de la Plateforme au moyen de ces identifiant et mot de passe est réputée avoir été faite par l’Utilisateur lui-même.</p>
            <p>L’Utilisateur est responsable de l'utilisation de la Plateforme et de toutes les actions réalisées au sein de la Plateforme avec son identifiant et son mot de passe, sauf si l'utilisation de son compte a été faite après sa désinscription.</p>

            <h2>Article 3 : Moyens de paiement</h2>
            <p>Welbe propose différents moyens de paiement, dont certains sont gérés par des partenaires et des prestataires de paiement pour lesquels des conditions particulières et des frais supplémentaires peuvent s’appliquer. L’Utilisateur doit prendre connaissance de ces conditions particulières et de ces frais supplémentaires éventuels préalablement à la confirmation de son inscription. Ces services de paiement sont fournis par les prestataires de paiement concernés sous leur seule responsabilité.</p>

            <h2>Article 4 : Droit de rétractation</h2>
            <p>Conformément à l’article L. 221-28 du Code de la consommation, l’Utilisateur reconnait comprendre que la formation au Three.js est un contenu numérique non fourni sur support matériel dont l’exécution a commencé lors de son inscription à la Plateforme et qu’il renonce expressément à son droit de rétractation.</p>

            <h2>Article 5 : Propriété intellectuelle</h2>
            <p>L'ensemble des éléments techniques, graphiques, textuels ou autres constituant la Plateforme (notamment les textes, graphismes, photographies, images, vidéos, sons, charte graphique, codes sources, noms, marques, logos, visuels, bases de données, etc.) ainsi que la Plateforme sont la propriété exclusive de Welbe.</p>
            <p>L'Utilisateur reconnait qu'aucune propriété d’aucune sorte ne lui est transmise, et qu'aucun droit ou licence ne lui est accordé, en dehors d'un droit d'utiliser la Plateforme conformément aux présentes pendant la durée du Contrat, et des droits d'utilisation de la formation.</p>
            <p>En conséquence, sauf autorisation expresse et préalable de Welbe, l'Utilisateur s'engage à ne pas :</p>
            <ul>
                <li>Reproduire, à des fins commerciales ou non, tout élément, et notamment tout texte (à l’unique exception des exemples de lignes de code prévus dans les exercices de la formation), image ou vidéo, présents au sein de la Plateforme ;</li>
                <li>Intégrer tout ou partie du contenu de la Plateforme dans un site tiers, à des fins commerciales ou non ;</li>
                <li>Copier tout élément, et ce quel que soit le support, présent au sein de la Plateforme, et notamment tout texte (à l’unique exception des exemples de lignes de code prévus dans les exercices de la formation), image ou vidéo, présents au sein de la Plateforme.</li>
            </ul>
            <p>En conséquence, toute utilisation non expressément autorisée d'éléments de la Plateforme engage la responsabilité civile et/ou pénale de son auteur et sera susceptible d'entraîner des poursuites judiciaires à son encontre.</p>

            <h2>Article 6 : Protection des données personnelles</h2>
            <p>Welbe collecte et utilise les données personnelles de l'Utilisateur dans le respect des dispositions de la loi n°78-17 du 6 janvier 1978 dite « Informatique et Libertés ».</p>
            <p>Les données collectées ont pour finalité :</p>
            <ul>
                <li>Le bon fonctionnement et l'amélioration de la Plateforme ;</li>
                <li>L’envoi d’informations par courriel aux membres ;</li>
                <li>La gestion de la relation de Welbe avec ses Utilisateurs (fichiers clients, facturation, gestion contractuelle, support client).</li>
            </ul>
            <p>Welbe ne cède aucune donnée personnelle à des tiers.</p>
            <p>Ces données sont stockées au sein de l'Union Européenne.</p>
            <p>Welbe s'engage à ne pas porter atteinte à la vie privée de l'Utilisateur et à prendre toutes précautions utiles afin de préserver la sécurité et la confidentialité de ses données personnelles et notamment d'empêcher qu'elles soient modifiées, endommagées ou communiquées à des personnes non autorisées, sous réserve des obligations qui pourraient lui incomber au sens de la loi n°2004-575 dite pour la Confiance dans l'Economie Numérique.</p>
            <p>L'Utilisateur dispose, à l'égard de toutes les données collectées ou générées par son usage de la Plateforme, d'un droit d'opposition, d'accès, de rectification et de suppression.</p>
            <p>L'exercice de ces droits se fera dans le respect des conditions prévues par la Loi « Informatique et Libertés » du 6 janvier 1978, en transmettant la demande à Welbe par email à l'adresse suivante : <a href="mailto:contact@welbe.fr">contact@welbe.fr</a></p>
            <p>Dans ce cas, l'Utilisateur devra préciser ses nom et prénom, et joindre la copie recto/verso d'une pièce d'identité en vigueur, afin que Welbe puisse vérifier son identité.</p>

            <h2>Article 7 : Cookies</h2>
            <p>Afin de permettre à l'Utilisateur de ne pas avoir à s'identifier à chaque accès à la Plateforme au cours d'une même journée, hormis lors du premier accès, Welbe utilise des cookies de session. Ces fichiers déposés sur l'ordinateur permettent d'identifier l'Utilisateur lors de chacune de ses connexions à la Plateforme.</p>
            <p>Par ailleurs, Welbe utilise des cookies de mesures d'audience telles que le nombre de pages vues, le nombre de visites, l'activité des Utilisateurs au sein de la Plateforme et leur fréquence de retour, notamment grâce aux services de Matomo Analytics. Ces cookies permettent seulement l'établissement d'études statistiques sur le trafic des Utilisateurs de la Plateforme, dont les résultats sont totalement anonymes.</p>
            <p>L'Utilisateur peut accéder à toutes les informations contenues dans les fichiers en relation avec les cookies utilisés par Welbe conformément aux dispositions de la loi « Informatique et Libertés » du 6 janvier 1978, en transmettant la demande à Welbe par email à l'adresse suivante : <a href="mailto:contact@welbe.fr">contact@welbe.fr</a></p>
            <p>Dans ce cas, l'Utilisateur devra préciser ses nom et prénom, et joindre la copie recto/verso d'une pièce d'identité en vigueur, afin que Welbe puisse vérifier son identité.</p>
            <p>De façon générale, tout Utilisateur peut, s'il le souhaite, s'opposer à l'utilisation de cookies utilisés par Welbe en sélectionnant les paramètres appropriés de son navigateur pour désactiver les cookies.</p>

            <h2>Article 8 : Responsabilité de Welbe</h2>
            <p>Welbe ne sera responsable que des dommages directs subis par l'Utilisateur, dont il sera établi qu'ils résultent de l'inexécution par Welbe de ses obligations.</p>
            <p>En revanche, Welbe ne saurait en aucun cas être tenue responsable :</p>
            <ul>
                <li>Des dommages qui résulteraient du fait de l'Utilisateur, de difficultés inhérentes au fonctionnement du réseau Internet et plus généralement des réseaux de télécommunication, quelle que soit leur nature, du fait d'un tiers ou d'un cas de force majeure ;</li>
                <li>Des dommages indirects résultant de l'utilisation de la Plateforme, ceux-ci étant définis de façon non limitative comme les pertes d'exploitation (chiffre d'affaires, revenus ou bénéfices), les pertes d'opportunités, les préjudices d'image ou de réputation, préjudice commercial ou économique ;</li>
                <li>De toute perte de données subie par l'Utilisateur, même si elle est du fait de Welbe.</li>
            </ul>

            <h2>Article 9 : Résiliation du contrat</h2>
            <h3>9.1. Par un Utilisateur</h3>
            <p>Chaque Utilisateur inscrit peut demander la suppression de son compte en adressant sa demande à Welbe par email à l'adresse suivante : <a href="mailto:contact@welbe.fr">contact@welbe.fr</a>. Il peut également le faire depuis son profil.<br />La désinscription entraînera la résiliation du Contrat.</p>
            <p>Cette résiliation prendra effet dans un délai maximal de quinze (15) jours ouvrés à compter de la réception de la demande de désinscription par Welbe, et ce, sous réserve que l'Utilisateur ne soit plus engagé à cette date</p>

            <h3>9.2. Par Welbe</h3>
            <p>Welbe se réserve le droit de bloquer l'accès de l'Utilisateur à tout ou partie des fonctionnalités de la Plateforme, de façon temporaire ou définitive, ou de résilier unilatéralement le Contrat, sans préavis ni formalité préalable et sans aucune contrepartie, en cas de manquement de l'Utilisateur à ses obligations prévues au Contrat ou à toute disposition légale ou règlementaire applicable.</p>
            <p>La résiliation du Contrat entraînera la désinscription immédiate de l'Utilisateur, indépendamment de tous dommages et intérêts auxquels Welbe pourrait prétendre en réparation des préjudices résultant pour elle des manquements contractuels de l’Utilisateur.</p>
            <p>Par ailleurs, Welbe se réserve la possibilité de résilier à tout moment le Contrat sans avoir à justifier d'un quelconque motif, moyennant le respect d'un délai de préavis d'un mois à compter de la notification de ladite résiliation à l’Utilisateur.</p>

            <h2>Article 10 : Durée du contrat</h2>
            <p>Le Contrat est conclu pour toute la durée d'utilisation de la Plateforme par l’Utilisateur.</p>

            <h2>Article 11 : Droit Applicable</h2>
            <p>Le Contrat est régi par le droit français.</p>
            <p>En cas de litige entre les Parties à propos de la validité, de l’exécution ou de l’interprétation du Contrat, les Parties s’engagent à coopérer avec diligence et bonne foi en vue de trouver une solution amiable.</p>
            <p>
              <strong>
                Si aucun accord amiable n’est trouvé dans un délai de 2 (deux) mois à compter de la notification
                à l’autre Partie d’un tel différend, compétence exclusive est attribuée aux tribunaux compétents
                du ressort de la Cour d’Appel de Paris.
              </strong>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
