import React from "react";
import { useTranslation } from 'react-i18next';

// import regions from "../../../data/regions.json";

function Input({ type, name, label, register, rules, error, required = true, placeholder, col = "6" }) {
  const { t } = useTranslation();

  return (
    <div className={`form-group col-12 col-md-${col}`}>
      <label className="form-label" htmlFor={name}>
        {t(label)} {!required && `(${t("facultatif")})`}
      </label>
      {type !== "textarea" ? (
        <input
          {...register(name, rules)}
          type={type || "text"}
          name={name}
          id={name}
          className={`form-control${error ? " is-invalid" : ""}`}
          placeholder={t(placeholder) || ""}
        />
      ) : (
        <textarea
          {...register(name, rules)}
          name={name}
          id={name}
          className={`form-control${error ? " is-invalid" : ""}`}
          placeholder={t(placeholder) || ""}
          style={{minHeight: 70}}
        ></textarea>
      )}
    </div>
  );
}

export default function Adress({ register, errors }) {
  const { t } = useTranslation();
  const validation = {
    firstname: { required: true, minLength: 2 },
    lastname: { required: true, minLength: 2 },
    email: { required: true, pattern: /^[a-z0-9.!#$%&'*+=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/g },
    phone: { pattern: /^[0-9]+$/i },
    location: {required: true},
    city: {required: true},
    postalCode: {required: true},
    // region: {required: true},
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">{t("Adresse de facturation")}</h4>
      </div>
      <div className="card-body">
        <form className="form-row">
          <Input register={register} rules={validation.firstname} error={errors.firstname} name="firstname" label="Prénom" placeholder="Prénom" />
          <Input register={register} rules={validation.lastname} error={errors.lastname} name="lastname" label="Nom de famille" placeholder="Nom de famille" />
          <Input register={register} rules={validation.email} error={errors.email} name="email" type="email" label="Adresse Email" placeholder="Email" />
          <Input register={register} rules={validation.phone} error={errors.phone} name="phone" type="number" label="Numéro de téléphone" placeholder="Téléphone" required={false} />
          <Input register={register} rules={validation.location} error={errors.location} name="location" type="textarea" label="Adresse" placeholder="Adresse" col={12} />
          <Input register={register} rules={validation.city} error={errors.city} name="city" label="Ville" placeholder="Ville" />
          <Input register={register} rules={validation.postalCode} error={errors.postalCode} name="postalCode" label="Zip/Code postal" placeholder="Zip" />
          {/* <div className="form-group col-12 col-md-6">
            <label className="form-label" htmlFor="stateSelect">{t("Région")}</label>
            <select {...register("region")} className={`form-control${errors.region ? " is-invalid" : ""}`} name="region" id="stateSelect">
              <option value="">{t("Choisissez votre région")}</option>
              <optgroup label="France métropolitaine">
                {regions.map((reg, idx) => <option key={idx} value={reg.formatted_name}>{reg.name}</option>)}
              </optgroup>
              <optgroup label="DOM-TOM">
                <option value="guadeloupe">Guadeloupe (DOM)</option>
                <option value="guyane">Guyane (DOM)</option>
                <option value="iles-wallis-et-futuna">Iles Wallis et Futuna (TOM)</option>
                <option value="martinique">Martinique (DOM)</option>
                <option value="mayotte">Mayotte (DOM)</option>
                <option value="nouvelle-caledonie">Nouvelle-Calédonie (TOM)</option>
                <option value="polynesie-francaise">Polynésie française (TOM)</option>
                <option value="reunion">Réunion (DOM)</option>
                <option value="saint-barthelemy">Saint Barthélémy</option>
                <option value="saint-martin">Saint Martin</option>
                <option value="saint-pierre-et-miquelon">Saint-Pierre-et-Miquelon (CT)</option>
                <option value="terres-australes-et-antarctiques-francaises">Terres australes et antarctiques françaises (TOM)</option>
              </optgroup>
            </select>
          </div> */}
          <div className="col-12">
            <div className="custom-control custom-checkbox mt-4">
              <input type="checkbox" name="shippingAddress" className="custom-control-input" id="shippingAddress" checked readOnly />
              <label className="custom-control-label" htmlFor="shippingAddress">
                {t("L'adresse de livraison est la même que mon adresse de facturation.")}
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
