const CONFIG = {
  APP_NAME: "Welbe",
  CURRENCY: "€",
  SHIPPING_AMOUNT: 0,
  ANCHORS: [
    'accueil',
    'apropos',
    'pourquoi',
    'demonstration',
    'acheter',
    'temoignages',
    'contact',
    'faq',
    'newsletter'
  ],
  FIREBASE: {
    apiKey: "AIzaSyB-e2ryPoAAYkXTaVHdRpe2J5tnjuVy_C4",
    authDomain: "welbe-app.firebaseapp.com",
    projectId: "welbe-app",
    storageBucket: "welbe-app.appspot.com",
    messagingSenderId: "485510328609",
    appId: "1:485510328609:web:b4d588c99c0e43dda78e60",
    measurementId: "G-CS715TLB0L"
  }
};

export default CONFIG;
